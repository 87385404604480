@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");


html,
body {
  font-family: "Lato", sans-serif;
}

a {
  cursor: pointer;
}

.navlink {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
  text-decoration: none;
}

.navlink:hover {
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}

.navlink-active {
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}
